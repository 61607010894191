import React from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

function CookieConsent() {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);

  function giveCookieConsent() {
    setCookie("cookieConsent", true, { path: "/" });
  }

  return (
    <div className="fixed bottom-0 z-10 w-full bg-gray-900">
      <div className="flex flex-row place-content-between items-center p-4">
        <div className="inline-block w-full text-sm text-gray-100">
          This application uses cookies. By using our website and application,
          you agree to our use of cookies.{" "}
          <a
            href="https://www.sdu.dk/en/om_sdu/om_dette_websted/databeskyttelse"
            className="text-blue-500 hover:underline"
          >
            Learn more about data protection at SDU
          </a>
          .
        </div>
        <button
          onClick={giveCookieConsent}
          className="rounded-md bg-gray-700 px-4 py-2 text-sm font-semibold text-gray-100 hover:bg-gray-600"
        >
          Accept
        </button>
      </div>
    </div>
  );
}

export { CookieConsent };
