// import "bootstrap/dist/css/bootstrap.css";
import "./app.css";

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./app";
import { AuthProvider } from "./context/AuthProvider";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { CookiesProvider } from "react-cookie";

// package.json for buiding minified version
// "build": "esbuild src/main.tsx --bundle --minify --sourcemap --outfile=../static/bundle.js",

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
