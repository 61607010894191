import { Tab } from "@headlessui/react";
import React from "react";

import { WorkspaceHome } from "@/features/workspaces/workspace-home.component";
import { WorkspaceHomeTabPane } from "@/features/workspaces/workspace-home-tab-pane.component";
import { WorkspaceTabPane } from "@/features/workspaces/workspace-tab-pane.component";
import { Workspace } from "@/features/workspaces/workspace.component";
import { cn } from "@/utils/utils";

import * as types from "@/types";
import type { Updater } from "use-immer";

type WorkspacesProps = {
  id: string;
  openWorkspaces: string[];
  setOpenWorkspaces: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTabIndex: number;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
  tabCount: number;
  setTabCount: React.Dispatch<React.SetStateAction<number>>;
  workspaces: {
    [workspaceId: string]: types.WorkspaceType;
  };
  setWorkspaces: Updater<{
    [workspaceId: string]: types.WorkspaceType;
  }>;
  setWorkspaceIsExpanding: (workspaceId: string) => void;
  newWorkspace: (msg: { name: string; compounds: Array<string>; rules: Array<string> }) => void;
  deleteWorkspace: (workspaceId: string) => void;
  openWorkspace: (workspaceId: string) => void;
  closeWorkspace: (workspaceId: string) => void;
  addCompoundToUniverse: (compoundData: types.ChemicalCompoundType) => void;
  compounds: {
    [k: string]: types.ChemicalCompoundType;
  };
  rules: {
    [k: string]: types.RuleType;
  };
};

function Workspaces({
  id,
  openWorkspaces,
  setOpenWorkspaces,
  selectedTabIndex,
  setSelectedTabIndex,
  tabCount,
  setTabCount,
  workspaces,
  setWorkspaces,
  setWorkspaceIsExpanding,
  newWorkspace,
  deleteWorkspace,
  openWorkspace,
  closeWorkspace,
  addCompoundToUniverse,
  compounds,
  rules,
}: WorkspacesProps) {
  return (
    <>
      <div className="w-full">
        <Tab.Group
          selectedIndex={selectedTabIndex}
          onChange={(index) => {
            setSelectedTabIndex(index);
          }}
        >
          <Tab.List className="flex w-dvw border-b border-b-gray-200 bg-gray-100">
            <WorkspaceHomeTabPane />
            {openWorkspaces.map((tab) => (
              <WorkspaceTabPane key={tab} id={tab} closeWorkspace={closeWorkspace} />
            ))}
          </Tab.List>

          <Tab.Panels>
            <WorkspaceHome
              workspaces={workspaces}
              openWorkspaces={openWorkspaces}
              setOpenWorkspaces={setOpenWorkspaces}
              setSelectedTabIndex={setSelectedTabIndex}
              tabCount={tabCount}
              setTabCount={setTabCount}
              newWorkspace={newWorkspace}
              deleteWorkspace={deleteWorkspace}
              openWorkspace={openWorkspace}
              compounds={compounds}
              rules={rules}
            />

            {openWorkspaces.map((workspaceId, idx) => {
              return (
                <Workspace
                  key={idx + 1}
                  idx={idx}
                  workspaceId={workspaceId}
                  workspace={workspaces[workspaceId]}
                  setWorkspaceIsExpanding={setWorkspaceIsExpanding}
                  addCompoundToUniverse={addCompoundToUniverse}
                  compounds={compounds}
                  rules={rules}
                />
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}

export { Workspaces };
